<!-- =========================================================================================
    File Name: NotAuthorized.vue
    Description: Not Authorized Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/assets/images/pages/not-authorized.png"
        alt="graphic-not-authorized"
        class="mx-auto mb-4"
      >
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">
        You are not authorized!
      </h1>
      <p class="sm:mx-0 mx-4 sm:mb-12 mb-8">
        please contact administrator.
      </p>
      <vs-button 
        v-if="!showLoginPage"
        size="large"
        @click="redirectToWelcomePage" 
      >Back to Home Page</vs-button>
      <vs-button 
        v-if="showLoginPage"
        size="large"
        @click="redirectToLoginPage" 
      >Back to Login Page</vs-button>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase/app'
import 'firebase/auth'
import { computed } from '@vue/composition-api'

export default {
  name: 'NotAuthorized',
  setup(props, ctx) {

    const showLoginPage = computed(() => {
      const activeUser = ctx.root.$store.state.AppActiveUser

      return activeUser.userRole === 'guest'
    })

    const redirectToWelcomePage = () => {
      return ctx.root.$router.push({
        name: 'welcome',
      })
    }

    const redirectToLoginPage = () => {
      localStorage.removeItem('userInfo')
      
      const firebaseCurrentUser = firebase.auth().currentUser

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push('/pages/login').catch(() => {})
          })
      }

      return ctx.root.$router.push({
        name: 'page-login',
      })
    }

    return {
      redirectToWelcomePage,
      redirectToLoginPage,
      showLoginPage
    }
  }
}

</script>